import {
  ResolvedCUEElement,
  ResolvedExternalLinkElement,
  ResolvedInternalLinkElement,
} from "@src/app/hooks/useArticle/elements";
import { FC } from "react";
import { Link } from "react-router-dom";

// TODO: These should be generated from CUE typings if possible
// For a full list of annotations, see:
// https://github.com/SPHTech/poccue/blob/develop/service/engine/definition/shared/story-element-type/paragraph.xml

export type CUEAnnotationComponents =
  | Record<
      CUEAnnotationType,
      CUEParagraphAnnotationComponent | keyof HTMLElementTagNameMap
    >
  | undefined;

type DataAnnotation = {
  "data-annotationname"?: string;
  "data-annotationvalue"?: ResolvedCUEElement | string;
};
type AnnotationComponentProps = React.PropsWithChildren<{
  element?: (ResolvedCUEElement | string) & DataAnnotation;
}>;

export type CUEParagraphAnnotationComponent = FC<AnnotationComponentProps>;

export type CUEParagraphProps = {
  text: string;
  annotationComponents?: CUEAnnotationComponents;
};

export enum CUEAnnotationType {
  Bold = "bold",
  InternalLink = "internal_link",
  Italic = "italic",
  SubheadItalic = "subhead_italic",
  Strike = "strike",
  Subscript = "subscript",
  Underline = "underline",
  Superscript = "superscript",
  Capitals = "capitals",
  MarkOut = "mark-out",
  Note = "note",
  BoldItalic = "bolditalic",
  DropCap = "dropcap",
  Dateline = "dateline",
  LeadIn = "leadin",
  WireCredit = "wire_credit_c",
  Title = "title",
  Subtitle = "subtitle",
  TagonByline = "tagon_byline",
  ExternalLink = "external_link",
  ImageExternalLink = "img_external_link",
  ImageInternalLink = "img_internal_link",
  Debug = "debug",
}

export function DefaultDebug(props: AnnotationComponentProps) {
  const { ...rest } = props;

  return (
    <span
      data-testid="missing-annotation"
      className="debug"
      style={{
        outline: import.meta.env.DEV ? "solid 2px magenta" : "none",
        outlineStyle: "dotted",
      }}
      {...rest}
    />
  );
}

// TODO: Handle custom fields
// defined in // https://github.com/SPHTech/poccue/blob/develop/service/engine/definition/shared/story-element-type/paragraph.xml

function ExternalLink(props: AnnotationComponentProps) {
  if (!props.element) return <DefaultDebug {...props} />;

  const fields = props.element as ResolvedExternalLinkElement;
  const uriValue = fields.fields?.uri.value;
  const newWindow = !uriValue?.includes("thinkchina");

  return (
    // By default:
    // 1. We add rel="noopener" to prevent the new page from being able to access the window.opener object
    // 2. Browsers already add noopener behaviour when opening target=_blank links
    // 3. We want referrer information, so do NOT add rel=noreferrer
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={uriValue ?? "#"}
      target={newWindow ? "_blank" : "_self"}
      rel={fields.fields?.noFollow.value ? "nofollow noopener" : "noopener"}
    >
      {props.children}
    </a>
  );
}
function InternalLink(props: AnnotationComponentProps) {
  if (!props.element) return <DefaultDebug {...props} />;

  const fields = props.element as ResolvedInternalLinkElement;

  return (
    <Link
      reloadDocument
      to={fields.relation?.urlPath ?? ""}
      target={fields.fields?.newWindow?.value ? "_blank" : "_self"}
      rel={fields.fields?.noFollow?.value ? "nofollow noopener" : "noopener"}
    >
      {props.children}
    </Link>
  );
}
export const defaultAnnotationComponents: CUEAnnotationComponents = {
  [CUEAnnotationType.InternalLink]: InternalLink,
  [CUEAnnotationType.ExternalLink]: ExternalLink,
  [CUEAnnotationType.ImageExternalLink]: ExternalLink,
  [CUEAnnotationType.ImageInternalLink]: InternalLink,
  [CUEAnnotationType.Bold]: "b",
  [CUEAnnotationType.Italic]: "i",
  [CUEAnnotationType.SubheadItalic]: "i",
  [CUEAnnotationType.Strike]: "s",
  [CUEAnnotationType.Subscript]: "sub",
  [CUEAnnotationType.Superscript]: "sup",
  [CUEAnnotationType.Capitals]: function DefaultUppercase(
    props: AnnotationComponentProps,
  ) {
    return <span className="uppercase">{props.children}</span>;
  },
  [CUEAnnotationType.Underline]: "u",
  [CUEAnnotationType.MarkOut]: "mark",
  [CUEAnnotationType.BoldItalic]: function DefaultBoldItalic(
    props: AnnotationComponentProps,
  ) {
    return (
      <b>
        <i>{props.children}</i>
      </b>
    );
  },
  [CUEAnnotationType.DropCap]: function DefaultDropCap(
    props: AnnotationComponentProps,
  ) {
    return <span className="dropcap">{props.children}</span>;
  },
  [CUEAnnotationType.Dateline]: function DefaultDateline(
    props: AnnotationComponentProps,
  ) {
    return <span className="dateline">{props.children}</span>;
  },
  [CUEAnnotationType.LeadIn]: function DefaultLeadIn(
    props: AnnotationComponentProps,
  ) {
    return <span className="leadin">{props.children}</span>;
  },
  [CUEAnnotationType.WireCredit]: function DefaultWireCredit(
    props: AnnotationComponentProps,
  ) {
    return <span className="wire-credit">{props.children}</span>;
  },
  [CUEAnnotationType.Title]: function DefaultTitle(
    props: AnnotationComponentProps,
  ) {
    return <span className="title">{props.children}</span>;
  },
  [CUEAnnotationType.Subtitle]: function DefaultSubtitle(
    props: AnnotationComponentProps,
  ) {
    return <span className="subtitle">{props.children}</span>;
  },
  [CUEAnnotationType.TagonByline]: function DefaultTagonByline(
    props: AnnotationComponentProps,
  ) {
    return <span className="tagon-byline">{props.children}</span>;
  },
  [CUEAnnotationType.Note]: DefaultDebug,
  [CUEAnnotationType.Debug]: DefaultDebug,
};
