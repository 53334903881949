import { OG_SITE_NAME } from "@src/app/components/Blocks/MetaTags/Constants";
import MetaTags from "@src/app/components/Blocks/MetaTags/MetaTags";
import Pagination from "@src/app/components/Blocks/Pagination/Pagination";
import SearchResult from "@src/app/components/Blocks/SearchResult/SearchResult";
import AdSettings from "@src/app/components/Elements/Advertisement/AdSettings";
import CatfishAd from "@src/app/components/Elements/Advertisement/CatfishAd";
import { GoogleAdsSlotFactory } from "@src/app/components/Elements/Advertisement/helpers";
import PrestitialAd from "@src/app/components/Elements/Advertisement/PrestitialAd";
import GAData from "@src/app/components/Elements/GAData/GAData";
import Spinner from "@src/app/components/Elements/Spinner/Spinner";
import Column from "@src/app/components/Layouts/Column/Column";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import Row from "@src/app/components/Layouts/Row/Row";
import validateAndSanitizeTerm from "@src/app/helper/validateAndSanitizeTerm";
import useGlobalAdSetting from "@src/app/hooks/useGlobalAdSetting";
import useSearch from "@src/app/hooks/useSearch";
import { OutletContextType } from "@src/app/types/OutletContextType";
import { removeLeadingSlash } from "@src/app/utils/helpers";
import cx from "classnames";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import {
  useLocation,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";

import styles from "./Search.module.css";

function SearchForm({ keyword }: { keyword: string }) {
  const [searchTerm, setSearchTerm] = useState(keyword);
  function onInputChange(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    e.stopPropagation();
    setSearchTerm(e.target.value);
  }

  function onSearchButtonClick(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();

    // Validate and sanitize the user input
    const sanitizedSearchTerm = validateAndSanitizeTerm(searchTerm);
    window.open(`/search?keyword=${sanitizedSearchTerm}`, "_self");
  }

  return (
    <form className={styles.searchForm} onSubmit={onSearchButtonClick}>
      {searchTerm.trim() === "" ? (
        <p className={styles.errorMessage}>Please enter some keywords.</p>
      ) : null}
      <div className={styles.searchField}>
        <span className={styles.searchLabel}>Enter your keywords</span>
        <div>
          <input
            type="text"
            value={searchTerm}
            className={styles.searchInput}
            onChange={onInputChange}
          />
          <button
            className={cx(
              styles.searchButton,
              searchTerm.trim() === "" && styles.disabled,
            )}
            type="submit"
            disabled={searchTerm.trim() === ""}
            aria-label="Perform Search"
          >
            SEARCH
          </button>
        </div>
      </div>
    </form>
  );
}

export default function SearchPage() {
  const { setIsLoading } = useOutletContext<OutletContextType>(); // Access parent isLoading setter
  const { isPrestitialEnabled, topOverlayImpressions, isCatfishEnabled } =
    useGlobalAdSetting();

  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  // format spacing in URL
  const keyword = encodeURIComponent(searchParams.get("keyword") ?? "");
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 0;

  const { data: searchPayload, isLoading } = useSearch({
    keyword,
    page,
  });

  const articles = searchPayload?.data.results || [];
  const totalResult = searchPayload?.data.totalResults || 0;
  const totalPages = Math.ceil(totalResult / 10);

  // Notify parent component about the loading state
  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  return (
    <>
      <AdSettings />

      <MetaTags
        title={`Search | ${OG_SITE_NAME}`}
        description=""
        ogType=""
        slug={removeLeadingSlash(pathname)}
      />

      <GAData title="Search" level2="search" contenttype="index/listing page" />

      <PrestitialAd
        slot={GoogleAdsSlotFactory.prestitial()}
        isPrestitialEnabled={isPrestitialEnabled}
        topOverlayImpressions={topOverlayImpressions}
      />

      <MainContainer>
        <div className={styles.searchResultListing}>
          <Row>
            <Column>
              <SearchForm keyword={decodeURIComponent(keyword)} />
            </Column>
          </Row>

          <Row>
            <Column>
              {isLoading ? (
                <Spinner>Searching...</Spinner>
              ) : (
                <SearchResult
                  searchResults={articles}
                  totalResult={totalResult}
                />
              )}
            </Column>
          </Row>

          <Row>
            <Column>
              {isLoading ? null : (
                <div className={styles.paginationContainer}>
                  <Pagination isUseQueryParams totalPages={totalPages} />
                </div>
              )}
            </Column>
          </Row>
        </div>
      </MainContainer>

      <CatfishAd
        slot={GoogleAdsSlotFactory.catfish()}
        isCatfishEnabled={isCatfishEnabled}
      />
    </>
  );
}
