import ArticleCardContent from "@src/app/components/Blocks/ArticleCardContent/ArticleCardContent";
import MetaTags from "@src/app/components/Blocks/MetaTags/MetaTags";
import NotFound from "@src/app/components/Blocks/NotFound/NotFound";
import Pagination from "@src/app/components/Blocks/Pagination/Pagination";
import AdSettings from "@src/app/components/Elements/Advertisement/AdSettings";
import CatfishAd from "@src/app/components/Elements/Advertisement/CatfishAd";
import { GoogleAdsSlotFactory } from "@src/app/components/Elements/Advertisement/helpers";
import PrestitialAd from "@src/app/components/Elements/Advertisement/PrestitialAd";
import GAData from "@src/app/components/Elements/GAData/GAData";
import Spinner from "@src/app/components/Elements/Spinner/Spinner";
import SectionTitle from "@src/app/components/Elements/Typography/SectionTitle/SectionTitle";
import Column from "@src/app/components/Layouts/Column/Column";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import Row from "@src/app/components/Layouts/Row/Row";
import StandardCardLayout from "@src/app/components/Layouts/StandardCardLayout/StandardCardLayout";
import formatPublishedDate from "@src/app/helper/formatPublishedDate";
import useGlobalAdSetting from "@src/app/hooks/useGlobalAdSetting";
import useTagListingApi from "@src/app/hooks/useTagListingApi";
import { ArticleCard } from "@src/app/types/Article";
import { OutletContextType } from "@src/app/types/OutletContextType";
import {
  logEnd,
  logStart,
  removeLeadingSlash,
  replaceSpecialChars,
} from "@src/app/utils/helpers";
import { useEffect } from "react";
import {
  useLocation,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";

import styles from "./TagListing.module.css";

export default function TagListingPage() {
  const { setIsLoading } = useOutletContext<OutletContextType>(); // Access parent isLoading setter
  const { tagName } = useParams();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  // format spacing in URL
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 0;
  const timeStart = logStart();
  const { data: searchPayload, isLoading } = useTagListingApi({
    tagName: tagName || "",
    page,
  });
  logEnd("tagListing useTagListingApi", timeStart);

  // Notify parent component about the loading state
  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  const targeting = [
    {
      key: "page",
      value: "tag",
    },
    {
      key: "page_number",
      value: `${page + 1}`,
    },
    {
      key: "tctags",
      value: replaceSpecialChars(tagName || ""),
    },
  ];

  const { isPrestitialEnabled, topOverlayImpressions, isCatfishEnabled } =
    useGlobalAdSetting({
      targeting,
    });

  // Handle the string empty error
  const displayedTagName = searchPayload?.data.tagPageName.toString() || "";

  const articles = searchPayload?.data.results || [];

  const totalResult = searchPayload?.data.totalResults || 0;

  // divide total card display in a page to get totalPages
  const totalPages = Math.ceil(totalResult / 12);

  // Check if the tag exists or if there was an error fetching the data
  if (!isLoading && totalResult === 0) {
    // Redirect to 404 page
    return <NotFound />;
  }

  return (
    <>
      <AdSettings />

      <MetaTags
        title={`${displayedTagName} | Big reads, Opinion & Columns on China`}
        description={`${displayedTagName} News - Get latest ${displayedTagName} news today in China & Greater China. Find top ${displayedTagName} stories in China & Greater China at thinkchina.sg`}
        ogType="article"
        slug={removeLeadingSlash(pathname)}
      />

      <GAData
        title={displayedTagName}
        level2={tagName}
        contenttype="index/listing page"
        pagination={page}
      />

      <PrestitialAd
        slot={GoogleAdsSlotFactory.prestitial("tags")}
        isPrestitialEnabled={isPrestitialEnabled}
        topOverlayImpressions={topOverlayImpressions}
      />

      <MainContainer>
        <div className={styles.tagListing}>
          <Row>
            <Column>
              <SectionTitle>{displayedTagName}</SectionTitle>
            </Column>
          </Row>

          <Row>
            <Column>
              {isLoading ? (
                <Spinner />
              ) : (
                <div className={styles.standardCardLayoutContainer}>
                  <StandardCardLayout>
                    {articles.map((article: ArticleCard) => (
                      <ArticleCardContent
                        key={article.id}
                        articleTitle={article.articleName}
                        articlePath={article.articlePath}
                        authors={article.authors}
                        categoryLabelProps={{
                          href: article.category.path,
                          label: article.category.name,
                        }}
                        date={formatPublishedDate(article.publishedDate)}
                        hasAuthorAndDate
                        hasBackground
                        hasSidePadding
                        hasSnippet
                        hasTitleMargin
                        imgUrl={article.imgUrl}
                        imgWidth={article.imgWidth}
                        imgHeight={article.imgHeight}
                        paragraphContent={article.blurb}
                        isVideo={article.displayType == "video"}
                        displayType={article.displayType}
                      />
                    ))}
                  </StandardCardLayout>
                </div>
              )}
            </Column>
          </Row>

          <Row>
            <Column>
              {isLoading ? null : (
                <div className={styles.paginationContainer}>
                  <Pagination isUseQueryParams totalPages={totalPages} />
                </div>
              )}
            </Column>
          </Row>
        </div>
      </MainContainer>

      <CatfishAd
        slot={GoogleAdsSlotFactory.catfish("tags")}
        isCatfishEnabled={isCatfishEnabled}
      />
    </>
  );
}
